<template>
  <div :class="wrapperClass">
    <h1 class="text-size-h1 mb-m text-bold">{{ $metaInfo.title }}</h1>
    <div class="row mb-l">
      <div class="col-md-8 col-xs-12">
        <p>
          Если у&nbsp;вас возникли проблемы при работе с&nbsp;личным кабинетом,
          <a
            href="#"
            class="link link--pseudo text-bold copy-link"
            :class="{ 'is-active': isCopyed }"
            @click.prevent="handleCopy"
            ><span>скопируйте отчет</span
            ><span><BaseIcon glyph="done" />&nbsp;готово</span></a
          >
          и&nbsp;пришлите его в&nbsp;сообщения группы
          <a
            href="https://vk.com/nticontest"
            target="_blank"
            class="link text-bold"
            rel="noopener noreferrer"
            >НТО в&nbsp;VK</a
          >
          или на&nbsp;почту
          <a
            class="link link--pseudo text-bold"
            :href="mailToLink"
            >{{ $store.state.supportEmail }}</a
          >.
        </p>
      </div>
    </div>

    <div class="row mb-l">
      <div class="col-md-8 col-xs-12">
        <p>
          У вас нет технической проблемы, но есть вопрос? Загляните в раздел FAQ
          на
          <a
            class="link"
            href="https://ntcontest.ru/participants/faq/"
            target="_blank"
            rel="noopener noreferrer"
            >сайте НТО</a
          >. Если там нет ответа, напишите нам в сообщения
          <a
            class="link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://vk.com/nticontest"
            >группы НТО в VK</a
          >
          или на почту
          <a
            :href="mailToLink"
            class="link"
            >{{ $store.state.supportEmail }}</a
          >.
        </p>
      </div>
    </div>

    <BaseButton
      v-if="!user || !user.id"
      tag="a"
      :href="`${$store.state.clientApiPrefix}/auth/go`"
      >Перейти в личный кабинет</BaseButton
    >
  </div>
</template>
<script>
import Bowser from "bowser";
import { middleware } from "./middleware";
import { mapGetters } from "vuex";
import { MENTOR_ROLE, PARTICIPANT_ROLE } from "@/constants";
import dayjs from "@/plugins/dayjs";
import { copyToClipboard, delay } from "@/utils";
import { getCookie } from "@/utils/cookies";
export default {
  name: "HelpPage",
  beforeRouteEnter: middleware,
  data() {
    return {
      bowser: {},
      isCopyed: false,
    };
  },
  metaInfo() {
    return {
      title: `Служба поддержки`,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      age: "user/age",
      selectedList: "participant/selectedList",
      teamStageList: "participant/teamStageList",
    }),
    wrapperClass() {
      const layout = this.$store.state.ui.layout;
      const result = ["help-page", `help-page--variant-${layout}`];
      if (layout === "reg") {
        result.push("container");
      }
      return result;
    },
    profiles() {
      return this.$store.state.profile.profiles;
    },
    report() {
      const { age, user, bowser } = this;
      const consentStatuses = this.$store.state.user.consentStatuses;
      const result = {
        ["Сборка"]: this.$store.state.release,
        ["Время создания отчета"]: dayjs().format("D.MM.YY HH:mm Z"),
        ["Платформа"]: bowser.platform?.type,
        ["Система"]: `${bowser.os?.name} ${bowser.os?.version}`,
        ["Браузер"]: `${bowser.browser?.name} ${bowser.browser?.version} (${bowser.engine?.name})`,
        ["YM"]: getCookie("_ym_uid"),
      };
      const role = user?.role;
      const yandex_id =
        role === MENTOR_ROLE ? user?.yandex_id : user?.participant?.yandex_id;
      if (user) {
        result["Возраст"] = age;
        result.id = user?.id || "Не авторизован";
        result.talent_id = user?.talent_id || "Не авторизован";
        result.yandex_id = yandex_id || "Отсутствует";

        result["Роль"] =
          role === MENTOR_ROLE
            ? "Наставник"
            : role === PARTICIPANT_ROLE
            ? "Участник"
            : "Отсутствует";

        if (age < 18) {
          result["Статус файла согласия"] =
            consentStatuses[user.adult_confirmed] || "rejected";
        } else {
          result["Статус файла согласия"] = "Не требуется";
        }

        if (role === PARTICIPANT_ROLE) {
          const track = this.$store.state.participant.track;
          result["Трек НТО"] = track?.title;
          result["Выбранные профили"] = "Отсутствуют";
          result["Класс/Курс"] = user?.participant?.grade || "Отсутствует";

          // if (track?.alias === LOW_SCHOOL_ALIAS) {
          //   result["Предпочтительный трек"] =
          //     this.$store.state.participant.prefered_track?.title ||
          //     "Отсутствует";
          // }

          if (this.selectedList?.length) {
            const resultProfiles = this.selectedList
              .map((n) => {
                return this.profiles[n.profile_id]?.title;
              })
              .filter(Boolean);
            result["Выбранные профили"] = resultProfiles.join(", ");
          }

          if (this.teamStageList?.length) {
            const resultProfiles = this.teamStageList
              .map((n) => {
                const title = this.profiles[n.profile_id]?.title;
                const track =
                  this.$store.getters["participant/teamProfileTracks"][
                    n.profile_id
                  ];
                const trackTitle = this.$store.state.tracks[track];
                return `${title} (трек: ${trackTitle?.title || track})`;
              })
              .filter(Boolean);
            result["Профили командного этапа"] = resultProfiles.join(", ");
          }
        }
      } else {
        result["Пользователь не авторизован"] = "Да";
      }

      return Object.entries(result)
        .map(([k, v]) => {
          return `${k}: ${v}`;
        })
        .join("\n");
    },
    mailToLink() {
      const subject = "Обращение в службу поддержки НТО";
      return `mailto:${
        this.$store.state.supportEmail
      }?subject=${encodeURIComponent(subject)}`;
    },
  },
  created() {
    try {
      this.bowser = Bowser.parse(window.navigator.userAgent);
    } catch (error) {
      console.log("error", error);
    }
  },
  methods: {
    async handleCopy() {
      this.isCopyed = true;
      copyToClipboard(this.report);
      await delay(1500);
      this.isCopyed = false;
    },
  },
};
</script>

<style lang="less" scoped>
.copy-link {
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  position: relative;

  span {
    transition: opacity 0.2s;
  }

  & > span:nth-child(1) {
    opacity: 1;
  }
  & > span:nth-child(2) {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  }
  &.is-active > span:nth-child(1) {
    opacity: 0;
  }
  &.is-active > span:nth-child(2) {
    opacity: 1;
  }

  &.is-active {
    // color: @success-color;
    border-bottom-color: transparent !important;
  }
}
</style>
